<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <div class="ic-title">更换超级管理员</div>
      <a-row>
        <a-col :span="12">
          <a-form-item label="选择员工" name="newId" :rules="[{ required: true, message: '请选择业务类型' }]">
            <a-select v-model:value="form.newId" placeholder="请选择员工" allow-clear style="width: 100%" show-search
              :filter-option="filterOption">
              <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <p>当前超管：{{ form.name }}</p>
      <p style="margin-bottom: 20px;">绑定手机：{{ form.phoneNumberDesc }}</p>
      <a-row>
        <a-col :span="12">
          <a-form-item name="smsCode" label="短信验证码" :rules="[{ required: true, message: '请输入验证码' }]">
            <div style="display: flex; align-items: center;">
              <a-input v-model:value="form.smsCode" placeholder="输入验证码" />
              <span v-if="!validate" @click="getCountDown()" class="pc-re-code">获取验证码</span>
              <span v-else class="pc-re-code-num">{{ countodwn }}</span>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <a-form-item>
        <a-button type="primary" @click="showmodalSubmit" :loading="submitLoading">确认添加</a-button>
      </a-form-item>
    </a-form>
    <a-modal v-model:open="showModal" title="提示">
      <p style="padding: 20px 0;">
        更换后新超管账号角色会被重置成超级管理员，旧超管账号角色会被清除需要重新配置，确定操作吗？
      </p>
      <template #footer>
        <div style="text-align: center;">
          <a-button @click="submit" type="primary">确定</a-button>
          <a-button @click="showModal = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import dayjs from 'dayjs';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      form: {},
      showModal: false,
      countodwn: 59,
      validate: false,
      countIn: null,
      list: [],
    }
  },
  created() {

  },
  destroyed() {
    this.countodwn = 59;
    this.validate = false;
    clearInterval(this.countIn);
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async getCountDown() {
      try {
        let res = await this.$http.post('/auth/getSmsCode', {
          phoneNumber: this.form.phoneNumber
        })
        if (res.code == '200') {
          this.validate = true;
          this.countIn = setInterval(() => {
            if (this.countodwn > 1) {
              this.countodwn--
            } else {
              clearInterval(this.countIn);
              this.countodwn = 59;
              this.validate = false
            }
          }, 1000)
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async getDetail() {
      try {
        let res = await this.$http.get('/system/institutionsEmployee/getCurrentAdmin')
        if (res.code == '200') {
          this.list = res.list;
          this.form = res.data;
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async showmodalSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      this.showModal = true;
    },

    async submit() {
      let { newId, oldId, smsCode, phoneNumber } = this.form;
      try {
        await this.$http.post('/system/institutionsEmployee/replaceAdmin', { newId, oldId, smsCode, phoneNumber });
        this.$refs.form.resetFields();
        this.$message.success('更换超管成功');
        this.showModal = false;
        this.countodwn = 59;
        this.validate = false;
        clearInterval(this.countIn);
        this.$emit('close');
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },
    filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  }
}
</script>

<style scoped lang="scss">
.blueCopy {
  color: blue;
  text-decoration: underline;
}

.ic-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.formCon {
  .ant-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-col {
      width: 48%;
      margin: 0 5px;
      flex: 1;
    }
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}

.pc-re-code {
  color: #1677ff;
  display: inline-block;
  width: 140px;
  font-size: 12px;
  border: 1px solid #1677ff;
  text-align: center;
  margin-left: 12px;
  height: 30px;
  line-height: 28px;
  border-radius: 8px;
  cursor: pointer
}

.pc-re-code-num {
  @extend .pc-re-code;
  font-size: 14px;
  color: #999999;
  border: 1px solid #999999;
}
</style>
